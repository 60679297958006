/* Component: Image Gallery */

/* Import CSS for navigation styled like arrows with circular borders */
@import '../common/arrows-circle.module.css';

.foraSwiper swiper-slide {
  @apply h-auto;
}

swiper.height {
  max-height: 100%;
}
